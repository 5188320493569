// src/data/initialDetails.js

const initialDetails = [
  {
    id: 1,
    imgPath: "/assets/img/1.jpeg",
    name: "Jane Dust",
    email: "janedoe@gmail.com",
    address: "New Delhi, India",
  },
  {
    id: 2,
    imgPath: "/assets/img/2.jpeg",
    name: "Mary Rosamund.",
    email: "agra@rosie.com",
    address: "Tbilisi, India",
  },
  {
    id: 3,
    imgPath: "/assets/img/3.jpeg",
    name: "Sherlock Watson",
    email: "irene@johnlock.com",
    address: "Baker Street, India",
  },
  {
    id: 4,
    imgPath: "/assets/img/4.jpeg",
    name: "John Holmes",
    email: "mary@johnlock.com",
    address: "Baker Street, India",
  },
  {
    id: 5,
    imgPath: "/assets/img/5.jpeg",
    name: "Mycroft Lestrade",
    email: "britishgovt@gmail.com",
    address: "London, India",
  },
];

export default initialDetails;
